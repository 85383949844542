import { MultiUseHeroBanner } from '@gemini/brand/el/ui/templates/multi-use-hero-banner';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldFile,
  ITemplateFieldHeadlineContentGroup,
  ITemplateFieldRadio,
  ITemplateFieldSelect,
  ITemplateFieldString,
  ITemplateFieldVideoContent,
  TemplateType
} from '@gemini/shared/services/content/next-mantlecms';
import styles from './multi-use-homepage-hero.module.scss';

export interface IMultiUseHeroBannerData {
  bgLarge: ITemplateFieldFile;
  bgSmall: ITemplateFieldFile;
  brandSection: ITemplateFieldSelect;
  disableLazyload: ITemplateFieldCheckbox;
  headlineContent: ITemplateFieldHeadlineContentGroup;
  imageAltText: ITemplateFieldString;
  loopingVidDesktop: ITemplateFieldString;
  loopingVidMob: ITemplateFieldString;
  loopingVidVolColor: ITemplateFieldRadio;
  loopingVidVolHideShow: ITemplateFieldRadio;
  marginClass: ITemplateFieldCheckbox;
  moduleLink: ITemplateFieldString;
  templateLabelname: ITemplateFieldString;
  templateStyle: ITemplateFieldSelect;
  videoContent: ITemplateFieldVideoContent;
}

export interface IMultiUseHomepageHeroProps extends TemplateType {
  data: IMultiUseHeroBannerData;
  dataMobile: IMultiUseHeroBannerData | null;
  index?: number;
}

/*
  DRUPAL MUSTACHE: esteelauder\modules\custom\esteelauder_common\templates\styleguide_templates\styleguide_multi_use_homepage_v1\styleguide_multi_use_homepage_v1.mustache
  MAPPING: esteelauder\modules\custom\esteelauder_common\includes\templates.inc
*/

export function MultiUseHomepageHero({
  data,
  dataMobile,
  index
}: IMultiUseHomepageHeroProps) {
  return (
    <>
      {dataMobile && (
        <div className="block md:hidden">
          <MultiUseHeroBanner
            {...dataMobile}
            classes={styles['multiuse-homepage-hero']}
            isHome
          />
        </div>
      )}
      <div className={`${dataMobile ? 'hidden md:block' : ''}`}>
        <MultiUseHeroBanner
          {...data}
          classes={styles['multiuse-homepage-hero']}
          isHome
          isDesktop
          index={index}
        />
      </div>
    </>
  );
}

export default MultiUseHomepageHero;
